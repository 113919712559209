import Input from "./Input";
import shouldRenderSection from "../../../../../utils/shouldRenderSection";
import { IProps } from "./types";
import { memo, useMemo } from "react";
import { usePriceManagement } from "../../../hooks";

const InputPriceList: React.FC<IProps> = ({
  value,
  region,
  channel,
  setValue,
  proposal,
}) => {
  const { userType } = usePriceManagement();

  const isUserPet = useMemo(() => {
    return /pet/i.test(String(userType));
  }, [userType]);

  if (!isUserPet && (region.length === 0 || channel.length === 0)) return null;
  if (!shouldRenderSection(proposal, "lista_de_precos")) return null;
  return (
    <Input
      value={value}
      region={region}
      channel={channel}
      setValue={setValue}
      isUserPet={isUserPet}
    />
  );
};

export default memo(InputPriceList);
