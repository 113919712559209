import { useState, useEffect, useCallback } from "react";
import api from "../../../../../services/api";
import { Multiselect } from "../../../../../components/AutoComplete";
import { getInputValue } from "../../actions";
import { IInputProps } from "./types";

const Input: React.FC<IInputProps & { isUserPet: boolean }> = ({
  value,
  region,
  channel,
  setValue,
  isUserPet,
}) => {
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchOptions = useCallback(() => {
    setValue([]);

    api
      .post("/price/list/prices", {
        region: getInputValue(region),
        channel: getInputValue(channel),
      })
      .then((response) => {
        setOptions((current) => ({
          ...current,
          priceList: response.data.prices.map((item: any) => {
            return {
              key: +item.id_price,
              label: { label: item.name_price, value: item.id_price },
            };
          }),
        }));
      })
      .catch((error) => {
        setErrorMessage(`Erro ${error.response?.status || 501}`);
      });
  }, [channel, region, setValue]);

  const fetchOptionsPet = useCallback(() => {
    setValue([]);

    api
      .get("/prices/list/pricelists")
      .then((response) => {
        setOptions((current) => ({
          ...current,
          priceList: response.data.priceLists.map((item: any) => {
            return {
              key: +item.id_price,
              label: { label: item.name, value: item.id },
            };
          }),
        }));
      })
      .catch((error) => {
        setErrorMessage(`Erro ${error.response?.status || 501}`);
      });
  }, [setValue]);

  useEffect(() => {
    if (isUserPet) {
      fetchOptionsPet();
    } else {
      fetchOptions();
    }
  }, [fetchOptions, fetchOptionsPet, isUserPet]);

  return (
    <Multiselect
      label="Lista de Preços"
      value={value}
      options={options}
      setValue={setValue}
      errorMessage={errorMessage}
      noOptionsText="Por favor selecione uma filial e canal pricing"
    />
  );
};

export default Input;
