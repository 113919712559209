import { useCallback, useEffect, useMemo, useState } from "react";
import PageContainer from "../../components/PageContainer";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import InputOptions from "./InputOptions";
import TableProposal from "./TableProposal";
import TableProposalVisualize from "./TableProposalVisualize";
import Upload from "./Upload";
import Visualize from "./Visualize";
import { Header, PriceManagementTable } from "./components";
import { PriceManagementProvider } from "./hooks";

const PriceManagement: React.FC = () => {
  const { isUserHQ, isUserSDP, isUserSupport } = useAuth();

  const [tabList, setTabList] = useState<any>([]);
  const [userType, setUserType] = useState("");
  const [tabRequest, setTabRequest] = useState<any>({});
  const [showComponent, setShowComponent] = useState<string>("main");

  const navigate = useMemo(() => {
    return {
      main: () => setShowComponent("main"),
      upload: () => setShowComponent("upload"),
      visualize: () => setShowComponent("visualize"),
      inputOptions: () => setShowComponent("inputOptions"),
      tableProposal: () => setShowComponent("tableProposal"),
      tableProposalVisualize: () => setShowComponent("tableProposalVisualize"),
    };
  }, []);

  const getUserType = useCallback(() => {
    api.get("/users/get-user").then((response) => {
      setUserType(response.data.type);
    });
  }, []);
  useEffect(getUserType, [getUserType]);

  const ShowComponent = useCallback(() => {
    return (
      {
        main: <PriceManagementTable />,
        upload: <Upload />,
        visualize: <Visualize />,
        inputOptions: <InputOptions />,
        tableProposal: <TableProposal />,
        tableProposalVisualize: <TableProposalVisualize />,
      }[showComponent] || null
    );
  }, [showComponent]);

  return (
    <PriceManagementProvider
      value={{
        tabList,
        setTabList,
        tabRequest,
        setTabRequest,
        isUserHQ,
        isUserSDP,
        isUserSupport,
        navigate,
        showComponent,
        userType,
      }}
    >
      <PageContainer>
        <Header />
        <ShowComponent />
      </PageContainer>
    </PriceManagementProvider>
  );
};

export default PriceManagement;
