import { memo, useCallback } from "react";
import { FaDownload as DownloadSvg } from "react-icons/fa";
import XLSX from "xlsx";

import Button from "../../../../../../components/Button";
import { useUpload } from "../../../hooks";

import { getColumns, getExampleRow } from "./actions";

const DownloadExampleButton: React.FC = () => {
  const { nivel, condition } = useUpload();

  const downloadExcel = useCallback(() => {
    const header = getColumns(condition, nivel);
    const exampleRow = getExampleRow(header);

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(exampleRow, { header });

    XLSX.utils.book_append_sheet(wb, ws1);
    XLSX.writeFile(wb, `${condition}-${nivel}-Exemplo.xlsx`);
  }, [nivel, condition]);

  return (
    <Button
      onClick={downloadExcel}
      containerStyle={{
        width: 200,
        height: 30,
        color: "#DFAC03",
        backgroundColor: "transparent",
        border: "1px solid transparent",
      }}
    >
      <DownloadSvg style={{ marginRight: "1rem" }} />
      Download planilha modelo
    </Button>
  );
};

export default memo(DownloadExampleButton);
