import Input from "./Input";
import shouldRenderSection from "../../../../../utils/shouldRenderSection";
import { IProps } from "./types";
import { usePriceManagement } from "../../../hooks";
import { memo } from "react";

const InputChannel: React.FC<IProps> = ({
  value,
  region,
  setValue,
  proposal,
}) => {
  const { userType } = usePriceManagement();

  if (/np/i.test(proposal)) return null;
  if (/pet/i.test(String(userType))) return null;
  if (!shouldRenderSection(proposal, "canal_pricing")) return null;
  return <Input value={value} region={region} setValue={setValue} />;
};

export default memo(InputChannel);
