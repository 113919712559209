import { useState, useRef, useMemo, useEffect, useCallback, memo } from "react";
import { Box } from "@material-ui/core";
import api from "../../../services/api";
import Paper from "../../../components/Paper";
import Button from "../../../components/Button";
import Select from "../../../components/Select";
import AlertModal from "../../../components/AlertModal";
import LoadingScreen from "../../../components/LoadingScreen";
import shouldRenderSection from "../../../utils/shouldRenderSection";
import { Multiselect } from "../../../components/AutoComplete";
import { usePriceManagement } from "../hooks/usePriceManagement";
import { defOptions } from "./actions/getInitialState/constants";
import {
  Niveis,
  Conditions,
  InputClient,
  InputChannel,
  InputCnpjRoot,
  InputPriceGroup,
  InputCategoryItems,
  InputSaleDocumentType,
} from "./components";
import { getInputValue, getInitialState } from "./actions";
import {
  Inputs,
  Header,
  Container,
  InputsTop,
  InputsBottom,
  InputsMiddle,
} from "./styles";
import {
  IOptions,
  TOnSubmit,
  TMultiSelect,
  TOptionsMaterial,
  TGenerateRequest,
  TOptionsPriceList,
  THandleInputRender,
} from "./types";
import InputPriceList from "./components/InputPriceList";

const defaultCompanyOption = defOptions.company[0].value;
const defaultExpConditionOption = defOptions.expCondition[0].value;

const InputOptions: React.FC = () => {
  const { setTabList, setTabRequest, isUserHQ, navigate } =
    usePriceManagement();

  // Page Functionality
  const [nivel, setNivel] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [condition, setCondition] = useState<string>("");

  // Selects
  const [options, setOptions] = useState<IOptions>({} as IOptions);
  const [client, setClient] = useState<TMultiSelect>([]);
  const [region, setRegion] = useState<TMultiSelect>([]);
  const [channel, setChannel] = useState<TMultiSelect>([]);
  const [category, setCategory] = useState<TMultiSelect>([]);
  const [cnpjRoot, setCnpjRoot] = useState<TMultiSelect>([]);
  const [material, setMaterial] = useState<TMultiSelect>([]);
  const [hierarchy, setHierarchy] = useState<TMultiSelect>([]);
  const [priceList, setPriceList] = useState<TMultiSelect>([]);
  const [priceGroup, setPriceGroup] = useState<TMultiSelect>([]);
  const [clientGroups, setClientGroups] = useState<TMultiSelect>([]);
  const [categoryItems, setCategoryItems] = useState<TMultiSelect>([]);
  const [saleDocumentType, setSaleDocumentType] = useState<TMultiSelect>([]);

  // Text inputs
  const [company, setCompany] = useState<string>(defaultCompanyOption);
  const [expCondition, setExpCondition] = useState<string>(
    defaultExpConditionOption
  );
  const [distributionChannel, setDistributionChannel] = useState<string>("");

  const proposal = useMemo(() => {
    if (!condition || !nivel) return "";
    return `${condition}-${nivel}`;
  }, [condition, nivel]);

  const errorRef = useRef<any>(null);
  const noResultsRef = useRef<any>(null);
  const invalidFileRef = useRef<any>(null);

  const toggleError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const toggleNoResults = useCallback(() => {
    noResultsRef.current.toggle();
  }, []);

  useEffect(() => {
    getInitialState((data) => {
      if (data.error) return console.error(data.message);
      setOptions(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setNivel("");
  }, [condition]);

  useEffect(() => {
    setExpCondition("");
    setPriceList([]);
  }, [region, channel]);

  useEffect(() => {
    setMaterial([]);
  }, [category]);

  useEffect(() => {
    setClient([]);
    setRegion([]);
    setChannel([]);
    setCategory([]);
    setCnpjRoot([]);
    setMaterial([]);
    setHierarchy([]);
    setPriceList([]);
    setPriceGroup([]);
    setClientGroups([]);
    setCategoryItems([]);
    setSaleDocumentType([]);
    setDistributionChannel("");
    setCompany(defaultCompanyOption);
    setExpCondition(defaultExpConditionOption);
  }, [condition, nivel]);

  // Conditionaly renders inputs based on `condition-nivel`
  const handleInputRender: THandleInputRender = useCallback(
    (inputTag: string, Input: JSX.Element) => {
      return shouldRenderSection(proposal, inputTag) ? Input : null;
    },
    [proposal]
  );

  const shouldRender = useCallback(
    (tag: string) => {
      return shouldRenderSection(proposal, tag);
    },
    [proposal]
  );

  // Updates region based on condition
  const optionsRegion = useCallback(() => {
    api.get(`/price/list/regions?condition=${condition}`).then((response) => {
      setOptions((current) => ({
        ...current,
        region: response.data.regions.map((item: any) => {
          return {
            key: item.id,
            label: { label: item.name, value: item.id },
          };
        }),
      }));
    });
  }, [condition]);

  useEffect(() => {
    optionsRegion();
  }, [optionsRegion]);

  // Updates price list based on selected region and channel
  const optionsPriceList: TOptionsPriceList = useCallback(() => {
    if (!region?.length || !channel?.length) return;
    api
      .post("/price/list/prices", {
        region: getInputValue(region),
        channel: getInputValue(channel),
      })
      .then((response) => {
        setOptions((current) => ({
          ...current,
          priceList: response.data.prices.map((item: any) => {
            return {
              key: +item.id_price,
              label: { label: item.name_price, value: item.id_price },
            };
          }),
        }));
      });
  }, [region, channel]);

  useEffect(() => {
    optionsPriceList();
  }, [optionsPriceList]);

  // Updates price list based on selected category
  const optionsMaterials: TOptionsMaterial = useCallback(() => {
    api
      .post("material/list", { category: getInputValue(category) })
      .then((response) => {
        setOptions((current) => ({
          ...current,
          material: response.data.material.map((item: any) => ({
            key: +item.sku,
            label: { label: +item.sku + "", value: +item.sku },
          })),
        }));
      });
  }, [category]);

  useEffect(() => {
    optionsMaterials();
  }, [optionsMaterials]);

  // Submit functions
  const generateRequest: TGenerateRequest = () => ({
    nivel: nivel.slice(0, 2),
    condition: condition.slice(0, 4),
    client: getInputValue(client),
    cnpjRoot: getInputValue(cnpjRoot),
    material: getInputValue(material),
    category: getInputValue(category),
    hierarchy: getInputValue(hierarchy),
    priceGroup: getInputValue(priceGroup),
    priceList: priceList.length > 0 ? getInputValue(priceList) : [null], // Agradeça o daniel
    clientGroups: getInputValue(clientGroups),
    categoryItems: getInputValue(categoryItems),
    region: getInputValue(region),
    saleDocumentType: getInputValue(saleDocumentType),
    channel: distributionChannel ? distributionChannel : getInputValue(channel),
    company,
    expCondition,
  });

  const onSubmit: TOnSubmit = () => {
    setLoading(true);

    const request = generateRequest();

    setTimeout(() => {
      api
        .post("/price/listv2", request)
        .then((res) => {
          if (res.data.table.length > 0) {
            const newTabRequest = {
              ...request,
              proposal: `${request.condition}-${request.nivel}`,
              serviceOrder: res.data.serviceOrder,
              url: "/price/listv2",
              disableInputs: false,
              isUserHQ,
            };

            setTabList(res.data.table);
            setTabRequest(newTabRequest);

            navigate.tableProposal();
          } else {
            toggleNoResults();
          }
        })
        .catch(() => toggleError())
        .finally(() => setLoading(false));
    }, 2000);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Paper containerStyle={{ position: "relative", minHeight: "32rem" }}>
          <Container>
            <Header>Escolha uma condição:</Header>
            <Conditions condition={condition} setCondition={setCondition} />
            <Header style={{ display: condition ? "inherit" : "none" }}>
              Escolha o nível tabela:
            </Header>
            <Niveis condition={condition} nivel={nivel} setNivel={setNivel} />
            <Inputs hidden={!nivel}>
              <InputsTop>
                {handleInputRender(
                  "filial",
                  <Multiselect
                    label="Filial"
                    value={region}
                    options={options.region}
                    setValue={setRegion}
                  />
                )}
                {handleInputRender(
                  "canal_de_distribuicao",
                  <Select
                    name="distributionChannel"
                    label="Canal de distribuição"
                    value={distributionChannel}
                    options={options.distributionChannel}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setDistributionChannel(e.target.value)
                    }
                  />
                )}
                {handleInputRender(
                  "regiao",
                  <Multiselect
                    label="Região"
                    value={region}
                    options={options.region}
                    setValue={setRegion}
                  />
                )}
                <InputChannel
                  value={channel}
                  region={region}
                  proposal={proposal}
                  setValue={setChannel}
                />
                {handleInputRender(
                  "grupo_de_cliente",
                  <Multiselect
                    label="Grupo de Cliente"
                    value={clientGroups}
                    options={options.clientGroups}
                    setValue={setClientGroups}
                  />
                )}
                <InputCnpjRoot
                  value={cnpjRoot}
                  region={region}
                  proposal={proposal}
                  setValue={setCnpjRoot}
                />
                <InputPriceGroup
                  value={priceGroup}
                  region={region}
                  proposal={proposal}
                  setValue={setPriceGroup}
                />
                <InputClient
                  value={client}
                  region={region}
                  setValue={setClient}
                  shouldRender={shouldRender}
                />
                {handleInputRender(
                  "empresa",
                  <Select
                    name="company"
                    label="Empresa:"
                    value={company}
                    options={options.company}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setCompany(e.target.value)
                    }
                  />
                )}
                {handleInputRender(
                  "categoria",
                  <Multiselect
                    label="Categoria"
                    value={category}
                    options={options.category}
                    setValue={setCategory}
                  />
                )}
                <InputCategoryItems
                  value={categoryItems}
                  proposal={proposal}
                  setValue={setCategoryItems}
                  shouldRender={shouldRender}
                />
              </InputsTop>
              <InputsMiddle>
                <InputPriceList
                  value={priceList}
                  region={region}
                  channel={channel}
                  proposal={proposal}
                  setValue={setPriceList}
                />
                {region.length > 0 &&
                  handleInputRender(
                    "condicao_de_expedicao",
                    <Select
                      name="condition"
                      label="Condição de Expedição"
                      value={expCondition}
                      options={
                        region.length > 0
                          ? options.expCondition
                          : [
                              {
                                key: 0,
                                value: "",
                                label: "Por favor selecione uma filial",
                              },
                            ]
                      }
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setExpCondition(e.target.value)
                      }
                    />
                  )}
                <InputSaleDocumentType
                  value={saleDocumentType}
                  setValue={setSaleDocumentType}
                  shouldRender={shouldRender}
                />
              </InputsMiddle>
              <InputsBottom>
                <Box>
                  {handleInputRender(
                    "hierarquia",
                    <Multiselect
                      label="Hierarquia"
                      value={hierarchy}
                      options={options.hierarchy}
                      setValue={setHierarchy}
                    />
                  )}
                  {handleInputRender(
                    "material",
                    <Multiselect
                      label="Material"
                      value={material}
                      options={options.material}
                      setValue={setMaterial}
                    />
                  )}
                  <Button
                    type="button"
                    onClick={() => onSubmit()}
                    style={{
                      height: "40px",
                      maxWidth: 200,
                      marginTop: 20,
                      marginRight: "auto",
                      borderRadius: "10px",
                    }}
                  >
                    Criar
                  </Button>
                </Box>
              </InputsBottom>
            </Inputs>
          </Container>
        </Paper>
      )}
      <AlertModal
        ref={errorRef}
        type="error"
        message="Erro, tente novamente."
      />
      <AlertModal
        ref={noResultsRef}
        type="error"
        message="Nenhum resultado encontrado. Verifique os campos selecionados e tente novamente."
      />
      <AlertModal
        ref={invalidFileRef}
        type="error"
        message="Arquivo inválido, verifique e tente novamente."
      />
    </>
  );
};

export default memo(InputOptions);
