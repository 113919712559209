import { useState, useEffect, useCallback } from "react";
import api from "../../../../../services/api";
import { Multiselect } from "../../../../../components/AutoComplete";
import { getInputValue } from "../../actions";
import { IInputProps } from "./types";

const Input: React.FC<IInputProps> = ({ value, region, setValue }) => {
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchChannelBasedOnRegion = useCallback(() => {
    setValue([]);

    api
      .post("/price/list/channels", {
        region: getInputValue(region),
      })
      .then((response) => {
        setOptions(
          response.data.channels.map((item: any, index: number) => {
            return {
              key: index,
              label: { label: item.channel, value: item.channel },
            };
          })
        );
      })
      .catch((error) => {
        setErrorMessage(`Erro ${error.response?.status || 501}`);
      });
  }, [region, setValue]);

  useEffect(fetchChannelBasedOnRegion, [fetchChannelBasedOnRegion]);

  return (
    <Multiselect
      label="Canal Pricing"
      value={value}
      options={options}
      setValue={setValue}
      errorMessage={errorMessage}
    />
  );
};

export default Input;
